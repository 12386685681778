import React from 'react'
import Transition from "./src/transition"
import { GlobalStyles } from './src/styles/global';
import { PortalProvider } from "react-portal-hook";
import { PlayerProvider } from './src/components/Player/utils';


export default ({ element, props  }) => {
    return (
        <>
            <GlobalStyles/>
            <PlayerProvider>
                <PortalProvider>
                    {element}
                </PortalProvider>
            </PlayerProvider>
        </>
    )
}