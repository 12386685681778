import { css } from 'styled-components'
import { media } from './utils'

const type = {
    heading1: css`
        font-size: 45px;
        line-height: 50px;
        font-weight: 800;

        ${media.phone`
            font-size: 35px;
            line-height: 42px;
        `}
    `,
    heading2: css`
        font-weight: 600;
        font-size: 24px;
        line-height: 1.35em;

        ${media.phone`
            font-size: 19px;
            line-height: 23px;
        `}
    `,
    heading3: css`
        font-size: 20px;
        line-height: 1.35em;

        ${media.phone`
            font-size: 16px;
        `}
    `,
    body: css`

    `,
    caption: css`
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.045em;
        font-weight: 600;

        ${media.phone`
            font-size: 10px;
            line-height: 12px;
        `}
    `
}

export default type